import './Login.css';
import { useState } from 'react';
import { Button, CheckBox, Flex, MultiButton } from 'components/hulam_platform';
import { alert, callApi } from 'utils';
import { useAuth } from 'AuthProvider';

//import axios from "axios";
import { Input } from 'antd';

export const EmployeeLogin = () => {
  const [managerId, setManagerId] = useState(localStorage.getItem('op_id') || '');
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();

  /**@function  sendLoginRequest
   * 로그인 API 함수 호출 -> 직원
   * 로그인 성공시 login(response, type) 함수 호출 -> type은 "employee" (권한 체크)
   */
  const sendLoginRequest = async () => {
    if (managerId && userId && password) {
      const postData = {
        userId: managerId,
        email: userId,
        password: password,
      };

      const response = await callApi('api/platform/loginEmployee', postData);

      if (response.result) {
        if (response.tmpPassword === 'Y') {
          alert({
            icon: true,
            type: 'confirm',
            content: (
              <div>
                초기 비밀번호를 변경해야해요. <br /> 지금 변경하시겠어요?
              </div>
            ),
            okText: '확인',
            onOk: () => {
              login(response, 'employee');
              window.location.href = '/hulamSetting';
            },
            onCancel: () => {
              login(response, 'employee');
              window.location.href = '/hulamHome';
            },
          });
        } else {
          login(response, 'employee');
          window.location.href = '/hulamHome';
        }

        localStorage.setItem('op_id', managerId);
      } else if (response.password_wrong_cnt > 5) {
        alert({
          content: (
            <div>
              비밀 번호 시도 횟수가 5회를 초과했습니다.
              <br /> 1시간 뒤에 다시 시도해 주세요.
            </div>
          ),
        });
      } else if (response.password_wrong_cnt > 3) {
        alert({
          content: (
            <div>
              가입된 회원아이디가 아니거나 비밀번호가 틀립니다.비밀번호는 대소문자를 구분합니다.{' '}
              <br /> 총 5회 실패시에 1시간 동안 로그인을 할 수 없습니다.
              <br />
              <span style={{ color: 'red' }}>({response.password_wrong_cnt}/5)</span>
            </div>
          ),
        });
      } else {
        if (response.password_wrong_cnt >= 0) {
          alert({
            content: (
              <div>
                가입된 회원아이디가 아니거나 비밀번호가 틀립니다.비밀번호는 대소문자를 구분합니다.
                <br />
                <span style={{ color: 'red' }}>({response.password_wrong_cnt}/5)</span>
              </div>
            ),
          });
        } else {
          alert({
            content: (
              <div>
                가입된 회원아이디가 아니거나 비밀번호가 틀립니다.비밀번호는 대소문자를 구분합니다.{' '}
              </div>
            ),
          });
        }
      }
    } else {
      alert({ content: '계정 정보를 입력해주세요.' });
    }
  };

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <div
        className="register"
        style={{
          margin: '0px auto',
          padding: 209,
        }}
      >
        <section>
          <div className="section_inner">
            <div className="section_top">
              <img
                src="https://hulampro.co.kr/logo.png"
                className="section_img"
                alt="휴램 로고"
                height={30}
              />
              <h1 className="login_title">급여 아웃소싱의 혁신</h1>
            </div>
            <div className="section_info">
              <ul className="register_container">
                <li>
                  <Input
                    value={managerId}
                    onChange={e => setManagerId(e.target.value)}
                    placeholder="업무 대행사 아이디"
                    style={{ width: 380, height: 50 }}
                  />
                </li>
                <li>
                  <Input
                    value={userId}
                    onChange={e => setUserId(e.target.value)}
                    placeholder="이메일 주소"
                    style={{ width: 380, height: 50 }}
                  />
                </li>
                <li>
                  <Input.Password
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    placeholder="비밀번호"
                    style={{ width: 380, height: 50 }}
                  />
                </li>
                <li style={{ margin: '14px 0' }}>
                  <Flex justifyContent="space-between">
                    <CheckBox width="auto">로그인 상태 유지</CheckBox>
                    <MultiButton>
                      <Button
                        type="text"
                        onClick={() =>
                          alert({
                            icon: true,
                            content: '업무 대행사 계정 담당자에게 문의해 주세요.',
                            type: 'confirm',
                          })
                        }
                        style={{ padding: 0, fontSize: 14, fontWeight: 400 }}
                      >
                        아이디 찾기
                      </Button>
                      <Button
                        type="text"
                        onClick={() =>
                          alert({
                            icon: true,
                            content: '업무 대행사 계정 담당자에게 문의해 주세요.',
                            type: 'confirm',
                          })
                        }
                        style={{ padding: 0, fontSize: 14, fontWeight: 400 }}
                      >
                        비밀번호 찾기
                      </Button>
                    </MultiButton>
                  </Flex>
                </li>
                <li>
                  <Button style={{ width: '100%', height: 50 }} onClick={() => sendLoginRequest()}>
                    로그인
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
